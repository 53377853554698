<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            Sub-Category
            <v-spacer></v-spacer>
            <v-row>
              <v-col cols="12" sm="7">
                <v-text-field
                  dense
                  v-model="search"
                  append-icon="mdi-magnify"
                  solo
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row justify="end" class="px-5">
              <v-dialog
                v-model="dialog"
                persistent
                max-width="600px"
                :key="dialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="green" v-bind="attrs" v-on="on">
                    Add
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addSubcat" ref="addSubcat">
                    <v-card-title>
                      <span class="headline">Add Subcategory</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="name"
                              label="Sub-Category"
                              :rules="[rules.required]"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              dense
                              multiple
                              v-model="category"
                              class="mt-5"
                              label="Category"
                              :items="categoryArray"
                              item-text="name"
                              item-value="_id"
                              :rules="[rules.required]"
                              required
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              dense
                              multiple
                              v-model="stylCategory"
                              class="mt-5"
                              label="Styl-Category"
                              :items="stylCategoryArray"
                              item-text="name"
                              item-value="_id"
                              :rules="[rules.required]"
                              required
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              v-model="HSN"
                              :items="hsnArray"
                              item-text="HSNCode"
                              item-value="_id"
                              dense
                              class="mt-5"
                              :rules="[rules.required]"
                              label="HSN Code"
                              required
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="gstPercentage"
                              label="GST (slab1 in percentage)"
                              required
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="gstLevel2Percentage"
                              label="GST (slab2 in percentage)"
                              required
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="gstLevel2MinumumPrice"
                              label="GST (slab2 minimum price)"
                              required
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-file-input
                              v-model="categoryImg"
                              @change="getFiles"
                              label="Image"
                              outlined
                              dense
                            ></v-file-input>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="3" md="3">
                            <v-text-field
                              dense
                              v-model="size"
                              class="mt-5"
                              label="Size"
                            >
                            </v-text-field>
                          </v-col>

                          <v-col cols="12" sm="3" md="3" class="mt-5">
                            <v-btn @click="addsize()"> Add Size </v-btn></v-col
                          >
                          <v-row>
                            <span v-for="(s, a) in sizes" :key="a">
                              <v-chip
                                class="ma-2"
                                close
                                @click:close="del(a)"
                                >{{ s }}</v-chip
                              ></span
                            >
                          </v-row>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <p>Please Upload Size Chart</p>
                            <ImageComp
                              @stepper="winStepper"
                              :heading="'Upload Size Chart'"
                              :componentType="'sizeChart'"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialogclose()">
                        Close
                      </v-btn>
                      <v-btn
                        :disabled="!addSubcat"
                        color="blue darken-1"
                        text
                        @click="itemadd()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-row>
          </v-card-title>
          <!-- <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-select
                dense
                outlined
                v-model="categoryView"
                class="mt-5 mx-3"
                label="Category"
                :items="categoryArray"
                item-text="name"
                item-value="_id"
              >
              </v-select>
            </v-col>
          </v-row> -->
          <v-data-table
            :headers="headers"
            :items="user"
            :items-per-page="count"
            hide-default-footer
            class="elevation-1"
            :click:row="showData"
          >
            <template v-slot:[`item.name`]="{ item }">
              <span v-if="item.category" style="color: darkblue">{{
                item.name
              }}</span>
            </template>

            <template v-slot:[`item.category`]="{ item }">
              <span
                v-for="(cat, a) in item.category"
                :key="a"
                style="color: darkblue"
              >
                <span v-if="a < 3"> {{ cat.name }}</span>
                <span v-if="a < 2">,&nbsp;</span>
              </span>
              <span
                class="mx-2"
                style="text-transform: none"
                v-if="item.category.length > 4"
              >
                ...</span
              >
            </template>
            <template v-slot:[`item.styl`]="{ item }">
              <span
                v-for="(stylCat, b) in item.styl"
                :key="b"
                style="color: darkblue"
              >
                <span v-if="b < 3">{{ stylCat.name }}</span>
                <span v-if="b < 2">,&nbsp;</span>
              </span>
              <span
                class="mx-2"
                style="text-transform: none"
                v-if="item.styl.length > 3"
              >
                ...</span
              >
            </template>
            <template v-slot:[`item.image`]="{ item }">
              <img
                v-bind:src="baseURL + item.image"
                width="auto"
                height="50px"
              />
            </template>
            <template v-slot:[`item.size`]="{ item }">
              <span
                v-for="(s, c) in item.size"
                :key="c"
                style="color: darkblue"
              >
                <span v-if="c < 4">{{ s }}</span>
                <span v-if="c < 3">,&nbsp;</span>
              </span>
              <span
                class="mx-2"
                style="text-transform: none"
                v-if="item.size.length > 4"
              >
                ...</span
              >
            </template>
            <template v-slot:top>
              <v-dialog v-model="editdialog" max-width="600px">
                <v-card>
                  <v-card-title>
                    <span class="headline">Edit Subcategory</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="itemediting.name"
                            label="Sub-Category"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-select
                            dense
                            multiple
                            v-model="itemediting.category"
                            class="mt-5"
                            label="Category"
                            :items="categoryArray"
                            item-text="name"
                            item-value="_id"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="D6" md="12">
                          <v-select
                            dense
                            multiple
                            v-model="itemediting.styl"
                            class="mt-5"
                            label="Styl-Category"
                            :items="stylCategoryArray"
                            item-text="name"
                            item-value="_id"
                            :rules="[rules.required]"
                            required
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-select
                            v-model="HSN"
                            :items="hsnArray"
                            class="mt-5"
                            item-text="HSNCode"
                            item-value="_id"
                            dense
                            :rules="[rules.required]"
                            label="HSN Code"
                            required
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            v-model="gstPercentage"
                            label="GST (slab1 in percentage)"
                            readonly
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="6" md="6">
                          <v-text-field
                            v-model="gstLevel2Percentage"
                            label="GST (slab2 in percentage)"
                            readonly
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" sm="6" md="6">
                          <v-text-field
                            v-model="gstLevel2MinumumPrice"
                            label="GST (slab2 minimum price)"
                            readonly
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-img
                            height="150"
                            width="200"
                            contain
                            :src="baseURL + itemediting.image"
                          >
                          </v-img>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-file-input
                            v-model="categoryImg"
                            @change="getFiles"
                            label="Image"
                            outlined
                            dense
                          ></v-file-input>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="3" md="3">
                          <v-text-field
                            dense
                            id="text"
                            v-model="size"
                            class="mt-5"
                            label="Size"
                            :rules="[rules.required]"
                            required
                          >
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="3" md="3" class="mt-5">
                          <v-btn @click="editsize()"> Add Size </v-btn></v-col
                        >
                      </v-row>
                      <v-row>
                        <span v-for="(s, b) in a" :key="b">
                          <v-chip class="ma-2" close @click:close="eddel(b)">{{
                            s
                          }}</v-chip></span
                        >
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <p>Please Upload Size Chart</p>

                          <ImageComp
                            :singleImage="itemediting.sizeChart"
                            :pageId="itemediting._id"
                            @stepper="winStepper"
                            :heading="'Upload size Chart'"
                            :componentType="'sizeChart'"
                          />
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="editdialog = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="edit()">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                    >Are you sure you want to delete this Client?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deleteItemConfirm(true)"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog> -->
            </template>

            <template v-slot:[`item._id`]="{ item }">
              <v-icon small class="mr-2" @click="info(item._id)">
                mdi-eye
              </v-icon>
              <v-icon small class="mr-2" @click="itemedit(item)">
                mdi-pencil
              </v-icon>

              <v-dialog v-model="item.delete" max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-on="on"
                    v-bind="attrs"
                    color="red"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <v-card>
                  <v-card-title>
                    Are you sure you want to delete this Subcategory?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="item.delete = false"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItem(item)"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-data-table>
          <div class="pt-2">
            <v-pagination
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
              color="#FF0000"
            ></v-pagination>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ImageComp from "@/components/commonComponents/singleImage";
export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      chip: true,
      itemeditingsize: [],
      showsnackbar: false,
      msg: null,
      msg2: null,
      editdialog: false,
      ServerError: false,
      appLoading: false,
      dialogDelete: false,
      dialog: false,
      search: "",
      name: "",
      gstPercentage: null,
      gstLevel2Percentage: null,
      gstLevel2MinumumPrice: null,
      hsnArray: [],
      size: "",
      sizes: [],
      sizeArray: [],
      edsize: [],
      count: 20,
      currentPage: 1,
      pages: 0,
      totalData: 0,
      totalRows: "",
      itemid: "",
      category: [],
      categoryArray: [],
      stylCategory: [],
      stylCategoryArray: [],
      categoryView: "",
      itemediting: [],
      a: [],
      HSN: [],
      addSubcat: false,
      editeditem: {
        name: "",
      },
      headers: [
        { text: "Sub-category Name", value: "name" },
        { text: "Category", value: "category" },
        { text: "Style-category", value: "styl" },
        { text: "Image", value: "image" },
        { text: "Size", value: "size" },
        { text: "HSN Code", value: "HSN.HSNCode" },
        { text: "GST (in %)", value: "gstPercentage", align: "center" },
        { text: "Actions", value: "_id", sortable: false },
      ],
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
      user: [],
      data: [],
      sizeChart: null,
      categoryImg: null,
      cId: "",
      attachments: null,
      attachmentsformData: new FormData(),
    };
  },
  mounted() {
    this.getData();
    this.getCategory();
    this.getStylCategory();
    this.getHSN();
  },
  watch: {
    categoryView() {},
    currentPage() {
      this.getData();
    },
    search() {
      this.getData();
    },
    HSN() {
      var current = this.hsnArray.filter((x) => x._id === this.HSN);
      this.gstPercentage = current[0].gstPercentage;
      this.gstLevel2Percentage = current[0].gstLevel2Percentage;
      this.gstLevel2MinumumPrice = current[0].gstLevel2MinumumPrice;
    },
  },
  methods: {
    getFiles() {
      var selectedFiles = this.categoryImg;
      if (this.categoryImg) {
        this.msg = null;
        this.attachments = selectedFiles;
      }
    },
    addsize() {
      this.sizes.push(this.size);
    },
    editsize() {
      //  console.log(this.itemediting.edsize)
      // this.a=this.itemediting.edsize
      document.getElementById("text").value = "";
      this.a.push(this.size);
    },
    winStepper(window_data) {
      if (window_data.type == "sizeChart") {
        this.sizeChart = window_data.selectedFiles;
      }
    },

    del(a) {
      this.sizes.splice(a);
    },
    eddel(b) {
      // this.a=this.itemediting.edsize
      this.a.splice(b);
    },
    showData() {},
    deleteItem(subcat) {
      var data = {};
      data["id"] = subcat._id;
      axios({
        url: "/subcategory/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Deleted Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/subcategory/getalllist",

        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          keyword: this.search,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.sizeArray = response.data.data.size;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getHSN() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/hsn/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.hsnArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },
    getCategory() {
      this.appLoading = true;
      axios({
        url: "/category/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.categoryArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getStylCategory() {
      this.appLoading = true;
      axios({
        url: "/styl/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.stylCategoryArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.name = null;
      this.category = null;
      this.stylCategory = null;
      this.gstPercentage = null;
      this.gstLevel2MinumumPrice = null;
      this.gstLevel2Percentage = null;
      this.size = null;
      this.dialog = false;
    },

    itemadd() {
      var data = {};
      data["name"] = this.name;
      data["category"] = this.category;
      data["styl"] = this.stylCategory;
      data["size"] = this.sizes;
      data["HSN"] = this.HSN;
      data["gstPercentage"] = this.gstPercentage;
      data["gstLevel2Percentage"] = this.gstLevel2Percentage;
      data["gstLevel2MinumumPrice"] = this.gstLevel2MinumumPrice;
      axios({
        url: "/subcategory/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.dialog = false;
            this.name = null;
            this.category = null;
            this.stylCategory = null;
            this.gstPercentage = null;
            this.gstLevel2MinumumPrice = null;
            this.gstLevel2Percentage = null;
            this.size = null;
            this.uploadImage(response.data.id);
            this.cId = response.data.id;
            if (this.categoryImg) {
              this.uploadattachments();
            }
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    uploadImage(id) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("image", this.sizeChart);
      axios({
        method: "POST",
        url: "/subcategory/UploadSizeChart",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
            this.sizeChart = null;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadattachments() {
      this.categoryImg = null;
      this.attachmentsformData.append("image", this.attachments);
      this.attachmentsformData.append("id", this.cId);
      axios({
        method: "POST",
        url: "/subcategory/UploadImagesingle",
        data: this.attachmentsformData,

        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.attachmentsformData = new FormData();
            this.msg = "Upload Successfully";
            this.showsnackbar = true;
            var myTimeout = setTimeout(this.getData, 2000);
            console.log(myTimeout);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    itemedit(item) {
      this.itemediting = item;
      this.HSN = this.itemediting.HSN;
      this.gstPercentage = this.itemediting.gstPercentage;
      this.gstLevel2Percentage = this.itemediting.gstLevel2Percentage;
      this.gstLevel2MinumumPrice = this.itemediting.gstLevel2MinumumPrice;
      this.a = this.a.concat(this.itemediting.size);

      this.editdialog = true;
    },
    edit() {
      var data = {};
      data["id"] = this.itemediting._id;
      data["name"] = this.itemediting.name;
      data["category"] = this.itemediting.category;
      data["styl"] = this.itemediting.styl;
      data["size"] = this.a;
      data["HSN"] = this.HSN;
      data["gstPercentage"] = this.gstPercentage;
      data["gstLevel2MinumumPrice"] = this.gstLevel2MinumumPrice;
      data["gstLevel2Percentage"] = this.gstLevel2Percentage;
      axios({
        url: "/subcategory/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.params.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.editdialog = false;
          if (response.data.status) {
            this.msg = "Edited Sucessfully";
            this.a = [];
            this.showsnackbar = true;
            this.dialog = false;
            if (this.sizeChart) {
              this.uploadImage(this.itemediting._id);
            }
            if (this.categoryImg) {
              //   this.attachmentsformData.append("icon", this.attachments);
              this.uploadattachments1();
            }
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadattachments1() {
      this.categoryImg = null;
      this.attachmentsformData.append("image", this.attachments);
      this.attachmentsformData.append("id", this.itemediting._id);
      axios({
        method: "POST",
        url: "/subcategory/UploadImagesingle",
        data: this.attachmentsformData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.attachmentsformData = new FormData();
            this.msg = "Upload Successfully";
            this.showsnackbar = true;
            var myTimeout = setTimeout(this.getData, 2000);
            console.log(myTimeout);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    info(scid) {
      this.$router.push({
        name: "subCategoryDetails",
        params: { subid: scid },
      });
    },
  },
};
</script>
